import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import FormulaSLA from '../images/contratos/formula-sla.png'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Garantia de Disponibilidade (SLA)</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Garantia de Disponibilidade (SLA)</H5>
                <ScrollAnim.Link
                  to='cobertura'
                  offsetTop='120'
                >
                  Cobertura
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='nivel-de-servico'
                  offsetTop='120'
                >
                  Nível de serviço
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='creditos'
                  offsetTop='120'
                >
                  Créditos
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='restricoes'
                  offsetTop='120'
                >
                  Restrições
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='limitacoes'
                  offsetTop='120'
                >
                  Limitações
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='restauracao-de-servidor-dedicado'
                  offsetTop='120'
                >
                  Restauração de<br /> Servidor Dedicado
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='reposicao-de-hardware'
                  offsetTop='120'
                >
                  Reposição de Hardware
                </ScrollAnim.Link>
                <Link
                  to='/termos-de-servico'
                >
                  Voltar para Termos do Serviço
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div id='cobertura'>
                  <H6>1. Cobertura</H6>
                  <Paragraph>Este acordo de nível de serviço (SLA) é aplicável estritamente aos planos de Colocation, Servidor Dedicado, Cloud Computing, Armazenamento em Storage, VPS, Revenda e Hosedagem que encontram-se adimplentes de suas cobranças na HostDime.com.br. Termos não definidos neste SLA seguem as regras anteriormente definidas em seu contrato com a HostDime.</Paragraph>
                </Div>
                <Div id='nivel-de-servico'>
                  <H6 style={{ marginTop: 24 }}>2. Nível de serviço</H6>
                  <Paragraph>A HostDime garante os seguintes níveis de disponibilidade em seus serviços:</Paragraph>
                  <Paragraph>Colocation</Paragraph>
                  <ul>
                    <li>- Infraestrutura: 99,98%;</li>
                    <li>- Rede: 99,50%;</li>
                  </ul>
                  <Paragraph>Servidor Dedicado, VPS, Revenda e Hospedagem</Paragraph>
                  <ul>
                    <li>- Infraestrutura: 99,98%;</li>
                    <li>- Rede: 99,50%;</li>
                    <li>- Hardware: Troca de hardware defeituoso em até 24h (por evento).</li>
                  </ul>
                  <Paragraph>Cloud Computing</Paragraph>
                  <ul>
                    <li>- Infraestrutura: 99,9%;</li>
                    <li>- Rede: 99,50%;</li>
                  </ul>
                  <Paragraph>Armazenamento em Storage</Paragraph>
                  <ul>
                    <li>- Infraestrutura: 99,9%;</li>
                    <li>- Rede: 99,50%;</li>
                  </ul>
                </Div>
                <Div id='creditos'>
                  <H6 style={{ marginTop: 24 }}>3. Créditos</H6>
                  <Paragraph>A HostDime se reserva ao direito de adicionar, excluir ou modificar qualquer cláusula deste termo a qualquer momento. Você concorda e se comprometerá a ajustar-se a estas mudanças, caso elas ocorram. Se você adquiriu um serviço, produto ou software com a HostDime, os termos abaixo continuarão em vigor enquanto você for um cliente ativo.</Paragraph>
                  <Paragraph>Fórmula para cálculo do SLA de disponibilidade:</Paragraph>
                  <Paragraph>
                    <img src={FormulaSLA} alt='Fórmula para cálculo do SLA' />
                    onde:
                  </Paragraph>
                  <ul>
                    <li>- TI é o Tempo de Indisponibilidade do serviço contratado (horas/mês);</li>
                    <li>- TT é o Tempo Total (horas/mês) = 720;</li>
                    <li>- VS é o Valor do Serviço contratado.</li>
                  </ul>
                  <Paragraph>Para que você possa receber o crédito em sua conta, você deve requerer tal crédito dentro de 10 (dez) dias após ter enfrentado indisponibilidade no serviço. Você precisa solicitar enviando um pedido ao nosso departamento financeiro através de sua Área do Cliente. No pedido deve conter seu domínio e/ou serviço, data e hora da indisponibilidade. Créditos são concedidos dentro de 60 dias de seu requerimento. O Crédito em sua conta deve ser uma compensação exclusiva nos casos de indisponibilidade do serviço. As estatísticas geradas pelos nossos sistemas de monitoramento internos são a determinação final do tempo de funcionamento do nosso serviço, portanto, ferramentas de terceiros não serão consideradas.</Paragraph>
                </Div>
                <Div id='restricoes'>
                  <H6 style={{ marginTop: 24 }}>4. Restrições</H6>
                  <Paragraph>Créditos não devem ser concedidos a você caso haja indisponibilidade do serviço resultante de (i) manutenção agendada, (ii) seu comportamento ou falha de seu equipamento, programas ou aplicações, ou (iii) circunstâncias além do controle racional da HostDime, incluindo, porém não limitando-se a: atos de qualquer corpo governamental, guerra, sabotagem, embargo, fogo, tsunami, furacão ou qualquer outro desastre natural, ataques DDoS, interrupções de comunicação de terceiros (incluindo propagação DNS), falha de software ou hardware.</Paragraph>
                </Div>
                <Div id='limitacoes'>
                  <H6 style={{ marginTop: 24 }}>5. Limitações</H6>
                  <Paragraph>Problemas online ocorrem continuamente. Pode chegar um tempo em que você poderá não conseguir acessar seu site ou qualquer outro serviço. Tenha em mente de que isto não necessariamente é um problema exclusivo na HostDime. Talvez seu provedor de internet esteja enfrentando dificuldades técnicas, ou talvez possa estar ocorrendo um problema de rota entre seu provedor de acesso a internet e o data center utilizado e mantido pela HostDime, tornando a comunicação impossível. Nós não podemos tomar a responsabilidade de tais problemas. Nossos agentes de monitoramento determinam o uptime de nossos serviços, e não do cliente.</Paragraph>
                </Div>
                <Div id='restauracao-de-servidor-dedicado'>
                  <H6 style={{ marginTop: 24 }}>6. Restauração de Servidor Dedicado</H6>
                  <Paragraph>A HostDime não é responsável pela restauração de dados do servidor. Nós recomendamos fortemente que você adquira alguma opção de backup para o seu servidor e mantenha cópia dos seus dados para intuitos emergenciais. Se houver falha de hardware e consequentemente perda de dados, você, o cliente, será o único responsável pela restauração dos dados. A HostDime não será responsável pela perda de dados em qualquer circunstância.</Paragraph>
                </Div>
                <Div id='reposicao-de-hardware'>
                  <H6 style={{ marginTop: 24 }}>7. Reposição de Hardware</H6>
                  <Paragraph>A Reposição de hardware ocorrerá dentro de até 24 horas (por evento) do problema reportado. A HostDime reembolsará por cada hora excedente, conforme cálculo de SLA disposto no item 3 . Para reduzir o tempo de reposição, nós mantemos uma certa quantidade de sistemas pré-instalados (com particionamento padrão) em caso de troca de discos rígidos para que o servidor volte a operar no mais curto espaço de tempo. Para requerer um crédito de violação de SLA, você precisa contatar nosso departamento financeiro através de sua Área do Cliente dentro de 10 dias após o ocorrido. Violações de SLA de hardware não cobre violação da rede.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Privacidade, Garantia de Disponibilidade (SLA)' />
      <PageTemplate
        title='Política de Privacidade, Garantia de Disponibilidade (SLA)'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
